import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    ArrayField,
} from 'react-admin';

import {
    BulkActionButtons,
    EditButtonCustom,
    SingleFieldListCustom,
} from '@src/custom';

import { source } from './data';

const CoBrandedVideoRequestsList = (props) => (
    <List
        {...props}
        title="Cobranded Video Requests"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={false}
        bulkActionButtons={<BulkActionButtons resource="co_branded_videos" {...props} />}
    >
        <Datagrid>
            <TextField
                label="First Name"
                source={source.firstName}
            />
            <TextField
                label="Last Name"
                source={source.lastName}
            />
            <TextField
                label="Email"
                source={source.requesterEmail}
            />
            <ArrayField
                label="Brands"
                source={source.brands}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <TextField
                label="Videos Requested"
                source={source.videosRequested}
            />
            <TextField
                label="Status"
                source={source.status}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <EditButtonCustom resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default CoBrandedVideoRequestsList;

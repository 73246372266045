import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { EditActions, MaxWidthEdit } from '@src/custom';
import Form from './form';

const EditAgency = (props) => {
    return (
        <MaxWidthEdit
            title="Edit Agency"
            actions={<EditActions />}
            {...props}
        >
            <SimpleForm
                redirect="edit"
                submitOnEnter={false}
                validate={validate}
            >
                <Form {...props} />
            </SimpleForm>
        </MaxWidthEdit>
    );
};

export default EditAgency;

/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { MenuItemLink, Responsive } from 'react-admin';

import { Taxonomies } from '@src/global/taxonomies';
import { Permissions } from '@src/global/permissions';
import Logo from './Logo';
import LogoutItem from './LogoutMenuItem';
import SubMenu from './SubMenu';

const useStyles = makeStyles((theme) => ({
    subMenuItemLink: {
        paddingLeft: theme.spacing(4),
    },
}));

const MenuCustom = ({ onMenuClick, logout }) => {
    const classes = useStyles();

    const [openItem, setOpenItem] = React.useState(null);

    const handleClick = React.useCallback((item) => {
        setOpenItem((openItem) => openItem === item ? null : item);
    }, [setOpenItem]);

    return (
        <>
            <Logo />

            {
                (Permissions('home_articles', 'read') === true || Permissions('home_links', 'read') === true) &&
                <SubMenu
                    currentItem={openItem}
                    item="homepage"
                    title="Homepage"
                    onClick={handleClick}
                >
                    {
                        Permissions('home_articles', 'read') === true &&
                            <MenuItemLink
                                key="home-articles"
                                className={classes.subMenuItemLink}
                                primaryText="Featured Content"
                                to="/featured_content"
                                onClick={onMenuClick}
                            />
                    }
                    {
                        Permissions('home_links', 'read') === true &&
                        <MenuItemLink
                            key="home-links"
                            className={classes.subMenuItemLink}
                            primaryText="Custom Homepage Links"
                            to="/home_links"
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }

            {
                Permissions('product_updates', 'read') === true &&
                <MenuItemLink
                    key="product-updates"
                    primaryText="Product News and Updates"
                    to="/product_updates"
                    onClick={onMenuClick}
                />
            }

            {
                (Permissions('underwriting_guidelines', 'read') === true || Permissions('rate_bulletins', 'read') === true) &&
                <SubMenu
                    currentItem={openItem}
                    item="guides"
                    title="UW Guides and Rate Bulletins"
                    onClick={handleClick}
                >
                    {
                        Permissions('underwriting_guidelines', 'read') === true &&
                        <MenuItemLink
                            key="underwriting-guidelines"
                            className={classes.subMenuItemLink}
                            primaryText="Underwriting Guidelines"
                            to="/underwriting_guidelines"
                            onClick={onMenuClick}
                        />
                    }
                    {
                        Permissions('rate_bulletins', 'read') === true &&
                        <MenuItemLink
                            key="rate-and-rule-manuals"
                            className={classes.subMenuItemLink}
                            primaryText="Rate Bulletins"
                            to="/rate_bulletins"
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }

            {
                (Permissions('trainings', 'read') === true || Permissions('job_aids', 'read') === true ||
                Permissions('webinars', 'read') === true) &&
                <SubMenu
                    currentItem={openItem}
                    item="trainings"
                    title="Training &amp; Job Aids"
                    onClick={handleClick}
                >
                    {
                        Permissions('trainings', 'read') === true &&
                        <MenuItemLink
                            key="online-training-modules"
                            className={classes.subMenuItemLink}
                            primaryText="eLearning"
                            to="/trainings"
                            onClick={onMenuClick}
                        />
                    }
                    {
                        Permissions('job_aids', 'read') === true &&
                        <MenuItemLink
                            key="job-aids"
                            className={classes.subMenuItemLink}
                            primaryText="Job Aids"
                            to="/job_aids"
                            onClick={onMenuClick}
                        />
                    }
                    {
                        Permissions('webinars', 'read') === true &&
                        <MenuItemLink
                            key="webinars"
                            className={classes.subMenuItemLink}
                            primaryText="Upcoming Live Webinars"
                            to="/webinars"
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }

            {
                Permissions('alerts', 'read') === true &&
                <MenuItemLink
                    key="alerts"
                    primaryText="Alerts"
                    to="/alerts"
                    onClick={onMenuClick}
                />
            }

            {
                Permissions('products', 'read') === true &&
                <MenuItemLink
                    key="product-availability-map"
                    primaryText="Product Availability"
                    to="/all_products"
                    onClick={onMenuClick}
                />
            }

            {
                Permissions('form_entries', 'read') === true &&
                <MenuItemLink
                    key="contact-forms"
                    primaryText="Contact Forms"
                    to="/contact_forms"
                    onClick={onMenuClick}
                />
            }

            {
                Permissions('categories', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="categories"
                    title="Categories"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="cat-product-updates"
                        className={classes.subMenuItemLink}
                        primaryText="Product updates"
                        to={`/taxonomies/${Taxonomies.productNewsAndUpdates}/categories`}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="cat-system-and-technical"
                        className={classes.subMenuItemLink}
                        primaryText="System and technical"
                        to={`/taxonomies/${Taxonomies.systemAndTechnical}/categories`}
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            {
                Permissions('images', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="media_files"
                    title="Media Files"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="featured-images"
                        className={classes.subMenuItemLink}
                        primaryText="Featured images"
                        to={{ pathname: '/images', search: `filter=${JSON.stringify({ folder: 'featured' })}` }}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="thumbnail-images"
                        className={classes.subMenuItemLink}
                        primaryText="Thumbnail images"
                        to={{ pathname: '/images', search: `filter=${JSON.stringify({ folder: 'thumbnail' })}` }}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="content-images"
                        className={classes.subMenuItemLink}
                        primaryText="Content images"
                        to={{ pathname: '/images', search: `filter=${JSON.stringify({ folder: 'content' })}` }}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="attachments"
                        className={classes.subMenuItemLink}
                        primaryText="Attachments"
                        to="/attachments"
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            {
                Permissions('co_branded_videos', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="co_branded_videos"
                    title="Cobranded Videos"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="co-branded-video-templates"
                        className={classes.subMenuItemLink}
                        primaryText="Video Templates"
                        to="/co_branded_video_templates"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="co-branded-video-requests"
                        className={classes.subMenuItemLink}
                        primaryText="Video Requests"
                        to="/co_branded_video_requests"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="co-branded-video-categories"
                        className={classes.subMenuItemLink}
                        primaryText="Video Categories"
                        to="/co_branded_video_categories"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="brands"
                        className={classes.subMenuItemLink}
                        primaryText="Brands"
                        to="/brands"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="co-branded-video-logos"
                        className={classes.subMenuItemLink}
                        primaryText="Co-branded Logos"
                        to="/co_branded_video_logos"
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            {
                Permissions('co_branded_marketing_collaterals', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="co_branded_marketing_collaterals"
                    title="Cobranded Marketing Collateral"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="collateral-categories"
                        className={classes.subMenuItemLink}
                        primaryText="Collateral Categories"
                        to="/collateral_categories"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="collateral-templates"
                        className={classes.subMenuItemLink}
                        primaryText="Collateral Templates"
                        to="/collateral_templates"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="collateral-requests"
                        className={classes.subMenuItemLink}
                        primaryText="Collateral Requests"
                        to="/collateral_requests"
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            {
                Permissions('page_expirations', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="expirations"
                    title="Expirations"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="page-expirations"
                        className={classes.subMenuItemLink}
                        primaryText="By page"
                        to="/page_expirations"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="items-expirations"
                        className={classes.subMenuItemLink}
                        primaryText="By item"
                        to="/item_expirations"
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            {
                Permissions('redirects', 'read') === true &&
                <MenuItemLink
                    key="redirects"
                    primaryText="Redirects"
                    to="/redirects"
                    onClick={onMenuClick}
                />
            }

            {
                (Permissions('settings', 'read') === true || Permissions('roles', 'read') === true) &&
                <SubMenu
                    currentItem={openItem}
                    item="security"
                    title="Security"
                    onClick={handleClick}
                >
                    {
                        Permissions('settings', 'read') === true &&
                        <MenuItemLink
                            key="sessions"
                            className={classes.subMenuItemLink}
                            primaryText="Sessions"
                            to="/session_timeouts"
                            onClick={onMenuClick}
                        />
                    }
                    {
                        Permissions('roles', 'read') === true &&
                        <MenuItemLink
                            key="roles"
                            className={classes.subMenuItemLink}
                            primaryText="Access & Permissions"
                            to="/roles"
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }

            {
                Permissions('promo_notification_settings', 'read') === true &&
                <MenuItemLink
                    key="promo_notification_settings"
                    primaryText="Rediscover Foremost"
                    to="/rediscover_foremost"
                    onClick={onMenuClick}
                />
            }

            {
                Permissions('users', 'read') === true &&
                <SubMenu
                    currentItem={openItem}
                    item="users"
                    title="Users"
                    onClick={handleClick}
                >
                    <MenuItemLink
                        key="employees"
                        className={classes.subMenuItemLink}
                        primaryText="Employees"
                        to="/employees"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="admins"
                        className={classes.subMenuItemLink}
                        primaryText="Administrators"
                        to="/admins"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="agencies"
                        className={classes.subMenuItemLink}
                        primaryText="Agencies"
                        to="/agencies"
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        key="agents"
                        className={classes.subMenuItemLink}
                        primaryText="VUE bio records"
                        to="/agents"
                        onClick={onMenuClick}
                    />
                </SubMenu>
            }

            <LogoutItem key="logout" />

            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </>
    );
};

MenuCustom.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default React.memo(MenuCustom);

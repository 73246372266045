/* eslint-disable complexity */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    InputLabel,
    FormControl,
    TextField,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useNotify } from 'react-admin';
import { apiPost, apiGet } from '@src/services/api/helpers';

import { Permissions } from '@src/global/permissions';

import styles from './setting.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 70,
    },
    formControl: {
        width: '100%',
        minWidth: 120,
        paddingTop: 10,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const PromoNotificationSettingsEdit = () => {
    const notify = useNotify();
    const classes = useStyles();

    const [isLoading, setLoading] = useState(true);
    const [states, setStates] = useState([]);

    const [selectedStates, setSelectedStates] = useState([]);
    const [homepageBannerEnabled, setHomepageBannerEnabled] = useState(false);
    const [homepageBannerContent, setHomepageBannerContent] = useState('');
    const [homepageBannerUrl, setHomepageBannerUrl] = useState('');
    const [homepageBannerUrlText, setHomepageBannerUrlText] = useState('');
    const [homepageBannerUrlEnabled, setHomepageBannerUrlEnabled] = useState(false);
    const [homepageBannerStartQuoteUrl, setHomepageBannerStartQuoteUrl] = useState('');
    const [homepageBannerStartQuoteUrlText, setHomepageBannerStartQuoteUrlText] = useState('');
    const [homepageBannerStartQuoteUrlEnabled, setHomepageBannerStartQuoteUrlEnabled] = useState(false);
    const [innerpageBannerEnabled, setInnerpageBannerEnabled] = useState(false);
    const [innerpageBannerContent, setInnerpageBannerContent] = useState('');
    const [innerpageBannerUrl, setInnerpageBannerUrl] = useState('');
    const [innerpageBannerUrlText, setInnerpageBannerUrlText] = useState('');
    const [innerpageBannerUrlEnabled, setInnerpageBannerUrlEnabled] = useState(false);
    const [innerpageBannerStartQuoteUrl, setInnerpageBannerStartQuoteUrl] = useState('');
    const [innerpageBannerStartQuoteUrlText, setInnerpageBannerStartQuoteUrlText] = useState('');
    const [innerpageBannerStartQuoteUrlEnabled, setInnerpageBannerStartQuoteUrlEnabled] = useState(false);
    const [publishDateTime, setPublishDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);

    const [selectedStates2, setSelectedStates2] = React.useState([]);
    const [homepageBanner2Enabled, setHomepageBanner2Enabled] = React.useState(false);
    const [homepageBanner2Content, setHomepageBanner2Content] = React.useState('');
    const [homepageBanner2Url, setHomepageBanner2Url] = React.useState('');
    const [homepageBanner2UrlText, setHomepageBanner2UrlText] = React.useState('');
    const [homepageBanner2UrlEnabled, setHomepageBanner2UrlEnabled] = React.useState(false);
    const [innerpageBanner2Enabled, setInnerpageBanner2Enabled] = React.useState(false);
    const [innerpageBanner2Content, setInnerpageBanner2Content] = React.useState('');
    const [innerpageBanner2Url, setInnerpageBanner2Url] = React.useState('');
    const [innerpageBanner2UrlText, setInnerpageBanner2UrlText] = React.useState('');
    const [innerpageBanner2UrlEnabled, setInnerpageBanner2UrlEnabled] = React.useState(false);
    const [publishDateTime2, setPublishDateTime2] = React.useState(null);
    const [endDateTime2, setEndDateTime2] = React.useState(null);

    const [countdownTimerEnabled, setCountdownTimerEnabled] = useState(false);
    const [overlapMessage, setOverlapMessage] = useState('');

    React.useEffect(() => {
        apiGet('/states').then((response) => {
            const { states } = response.data;

            setStates(states);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });

        apiGet('/settings/promo_notification_banners').then((response) => {
            const values = response.data.promo_notification_setting.setting_values;

            setHomepageBannerEnabled(values.homepage_banner_enabled);
            setHomepageBannerContent(values.homepage_banner_content);
            setHomepageBannerUrl(values.homepage_banner_url);
            setHomepageBannerUrlText(values.homepage_banner_url_text);
            setHomepageBannerUrlEnabled(values.homepage_banner_url_enabled);
            setHomepageBannerStartQuoteUrl(values.homepage_banner_start_quote_url);
            setHomepageBannerStartQuoteUrlText(values.homepage_banner_start_quote_url_text);
            setHomepageBannerStartQuoteUrlEnabled(values.homepage_banner_start_quote_url_enabled);
            setInnerpageBannerEnabled(values.innerpage_banner_enabled);
            setInnerpageBannerContent(values.innerpage_banner_content);
            setInnerpageBannerUrl(values.innerpage_banner_url);
            setInnerpageBannerUrlText(values.innerpage_banner_url_text);
            setInnerpageBannerUrlEnabled(values.innerpage_banner_url_enabled);
            setInnerpageBannerStartQuoteUrl(values.innerpage_banner_start_quote_url);
            setInnerpageBannerStartQuoteUrlText(values.innerpage_banner_start_quote_url_text);
            setInnerpageBannerStartQuoteUrlEnabled(values.innerpage_banner_start_quote_url_enabled);
            setSelectedStates(JSON.parse(values.states));
            setPublishDateTime(values.publish_datetime);
            setEndDateTime(values.end_datetime);

            setHomepageBanner2Enabled(values.second_homepage_banner_enabled);
            setHomepageBanner2Content(values.second_homepage_banner_content);
            setHomepageBanner2Url(values.second_homepage_banner_url);
            setHomepageBanner2UrlText(values.second_homepage_banner_url_text);
            setHomepageBanner2UrlEnabled(values.second_homepage_banner_url_enabled);
            setInnerpageBanner2Enabled(values.second_innerpage_banner_enabled);
            setInnerpageBanner2Content(values.second_innerpage_banner_content);
            setInnerpageBanner2Url(values.second_innerpage_banner_url);
            setInnerpageBanner2UrlText(values.second_innerpage_banner_url_text);
            setInnerpageBanner2UrlEnabled(values.second_innerpage_banner_url_enabled);
            setSelectedStates2(JSON.parse(values.second_states));
            setPublishDateTime2(values.second_publish_datetime);
            setEndDateTime2(values.second_end_datetime);

            setOverlapMessage(values.overlap_message);
            setCountdownTimerEnabled(values.countdown_timer_enabled);

            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading]);

    const handleHomepageBannerEnabledChange = React.useCallback((e) => {
        setHomepageBannerEnabled(e.target.checked);
    }, [setHomepageBannerEnabled]);

    const handleHomepageBannerContentChange = React.useCallback((e) => {
        setHomepageBannerContent(e.target.value);
    }, [setHomepageBannerContent]);

    const handleHomepageBannerUrlChange = React.useCallback((e) => {
        setHomepageBannerUrl(e.target.value);
    }, [setHomepageBannerUrl]);

    const handleHomepageBannerUrlTextChange = React.useCallback((e) => {
        setHomepageBannerUrlText(e.target.value);
    }, [setHomepageBannerUrlText]);

    const handleHomepageBannerUrlEnabledChange = React.useCallback((e) => {
        setHomepageBannerUrlEnabled(e.target.checked);
    }, [setHomepageBannerUrlEnabled]);

    const handleHomepageBannerStartQuoteUrlChange = React.useCallback((e) => {
        setHomepageBannerStartQuoteUrl(e.target.value);
    }, [setHomepageBannerStartQuoteUrl]);

    const handleHomepageBannerStartQuoteUrlTextChange = React.useCallback((e) => {
        setHomepageBannerStartQuoteUrlText(e.target.value);
    }, [setHomepageBannerStartQuoteUrlText]);

    const handleHomepageBannerStartQuoteUrlEnabledChange = React.useCallback((e) => {
        setHomepageBannerStartQuoteUrlEnabled(e.target.checked);
    }, [setHomepageBannerStartQuoteUrlEnabled]);

    const handleInnerpageBannerEnabledChange = React.useCallback((e) => {
        setInnerpageBannerEnabled(e.target.checked);
    }, [setInnerpageBannerEnabled]);

    const handleInnerpageBannerContentChange = React.useCallback((e) => {
        setInnerpageBannerContent(e.target.value);
    }, [setInnerpageBannerContent]);

    const handleInnerpageBannerUrlChange = React.useCallback((e) => {
        setInnerpageBannerUrl(e.target.value);
    }, [setInnerpageBannerUrl]);

    const handleInnerpageBannerUrlTextChange = React.useCallback((e) => {
        setInnerpageBannerUrlText(e.target.value);
    }, [setInnerpageBannerUrlText]);

    const handleInnerpageBannerUrlEnabledChange = React.useCallback((e) => {
        setInnerpageBannerUrlEnabled(e.target.checked);
    }, [setInnerpageBannerUrlEnabled]);

    const handleInnerpageBannerStartQuoteUrlChange = React.useCallback((e) => {
        setInnerpageBannerStartQuoteUrl(e.target.value);
    }, [setInnerpageBannerStartQuoteUrl]);

    const handleInnerpageBannerStartQuoteUrlTextChange = React.useCallback((e) => {
        setInnerpageBannerStartQuoteUrlText(e.target.value);
    }, [setInnerpageBannerStartQuoteUrlText]);

    const handleInnerpageBannerStartQuoteUrlEnabledChange = React.useCallback((e) => {
        setInnerpageBannerStartQuoteUrlEnabled(e.target.checked);
    }, [setInnerpageBannerStartQuoteUrlEnabled]);

    const handleStatesChange = React.useCallback((e) => {
        setSelectedStates(e.target.value);
    }, [setSelectedStates]);

    const handlePublishDateTimeChange = React.useCallback((e) => {
        setPublishDateTime(e.target.value);
    }, [setPublishDateTime]);

    const handleEndDateTimeChange = React.useCallback((e) => {
        setEndDateTime(e.target.value);
    }, [setEndDateTime]);

    const handleHomepageBanner2EnabledChange = React.useCallback((e) => {
        setHomepageBanner2Enabled(e.target.checked);
    }, [setHomepageBanner2Enabled]);

    const handleHomepageBanner2ContentChange = React.useCallback((e) => {
        setHomepageBanner2Content(e.target.value);
    }, [setHomepageBanner2Content]);

    const handleHomepageBanner2UrlChange = React.useCallback((e) => {
        setHomepageBanner2Url(e.target.value);
    }, [setHomepageBanner2Url]);

    const handleHomepageBanner2UrlTextChange = React.useCallback((e) => {
        setHomepageBanner2UrlText(e.target.value);
    }, [setHomepageBanner2UrlText]);

    const handleHomepageBanner2UrlEnabledChange = React.useCallback((e) => {
        setHomepageBanner2UrlEnabled(e.target.checked);
    }, [setHomepageBanner2UrlEnabled]);

    const handleInnerpageBanner2EnabledChange = React.useCallback((e) => {
        setInnerpageBanner2Enabled(e.target.checked);
    }, [setInnerpageBanner2Enabled]);

    const handleInnerpageBanner2ContentChange = React.useCallback((e) => {
        setInnerpageBanner2Content(e.target.value);
    }, [setInnerpageBanner2Content]);

    const handleInnerpageBanner2UrlChange = React.useCallback((e) => {
        setInnerpageBanner2Url(e.target.value);
    }, [setInnerpageBanner2Url]);

    const handleInnerpageBanner2UrlTextChange = React.useCallback((e) => {
        setInnerpageBanner2UrlText(e.target.value);
    }, [setInnerpageBanner2UrlText]);

    const handleInnerpageBanner2UrlEnabledChange = React.useCallback((e) => {
        setInnerpageBanner2UrlEnabled(e.target.checked);
    }, [setInnerpageBanner2UrlEnabled]);

    const handleStates2Change = React.useCallback((e) => {
        setSelectedStates2(e.target.value);
    }, [setSelectedStates2]);

    const handlePublishDateTime2Change = React.useCallback((e) => {
        setPublishDateTime2(e.target.value);
    }, [setPublishDateTime2]);

    const handleEndDateTime2Change = React.useCallback((e) => {
        setEndDateTime2(e.target.value);
    }, [setEndDateTime2]);

    const handleOverlapMessageChange = React.useCallback((e) => {
        setOverlapMessage(e.target.value);
    }, [setOverlapMessage]);

    const handleCountdownTimerEnabledChange = React.useCallback((e) => {
        setCountdownTimerEnabled(e.target.checked);
    }, [setCountdownTimerEnabled]);

    const handleSubmit = React.useCallback(() => {
        return apiPost('/settings/promo_notification_banners', {
            // eslint-disable-next-line camelcase
            promo_notification_setting: {
                // eslint-disable-next-line camelcase
                homepage_banner_enabled: homepageBannerEnabled,
                // eslint-disable-next-line camelcase
                homepage_banner_content: homepageBannerContent,
                // eslint-disable-next-line camelcase
                homepage_banner_url: homepageBannerUrl,
                // eslint-disable-next-line camelcase
                homepage_banner_url_text: homepageBannerUrlText,
                // eslint-disable-next-line camelcase
                homepage_banner_url_enabled: homepageBannerUrlEnabled,
                // eslint-disable-next-line camelcase
                homepage_banner_start_quote_url: homepageBannerStartQuoteUrl,
                // eslint-disable-next-line camelcase
                homepage_banner_start_quote_url_text: homepageBannerStartQuoteUrlText,
                // eslint-disable-next-line camelcase
                homepage_banner_start_quote_url_enabled: homepageBannerStartQuoteUrlEnabled,
                // eslint-disable-next-line camelcase
                innerpage_banner_enabled: innerpageBannerEnabled,
                // eslint-disable-next-line camelcase
                innerpage_banner_content: innerpageBannerContent,
                // eslint-disable-next-line camelcase
                innerpage_banner_url: innerpageBannerUrl,
                // eslint-disable-next-line camelcase
                innerpage_banner_url_text: innerpageBannerUrlText,
                // eslint-disable-next-line camelcase
                innerpage_banner_url_enabled: innerpageBannerUrlEnabled,
                // eslint-disable-next-line camelcase
                innerpage_banner_start_quote_url: innerpageBannerStartQuoteUrl,
                // eslint-disable-next-line camelcase
                innerpage_banner_start_quote_url_text: innerpageBannerStartQuoteUrlText,
                // eslint-disable-next-line camelcase
                innerpage_banner_start_quote_url_enabled: innerpageBannerStartQuoteUrlEnabled,
                states: JSON.stringify(selectedStates),
                // eslint-disable-next-line camelcase
                publish_datetime: publishDateTime,
                // eslint-disable-next-line camelcase
                end_datetime: endDateTime,
                // eslint-disable-next-line camelcase
                second_homepage_banner_enabled: homepageBanner2Enabled,
                // eslint-disable-next-line camelcase
                second_homepage_banner_content: homepageBanner2Content,
                // eslint-disable-next-line camelcase
                second_homepage_banner_url: homepageBanner2Url,
                // eslint-disable-next-line camelcase
                second_homepage_banner_url_text: homepageBanner2UrlText,
                // eslint-disable-next-line camelcase
                second_homepage_banner_url_enabled: homepageBanner2UrlEnabled,
                // eslint-disable-next-line camelcase
                second_innerpage_banner_enabled: innerpageBanner2Enabled,
                // eslint-disable-next-line camelcase
                second_innerpage_banner_content: innerpageBanner2Content,
                // eslint-disable-next-line camelcase
                second_innerpage_banner_url: innerpageBanner2Url,
                // eslint-disable-next-line camelcase
                second_innerpage_banner_url_text: innerpageBanner2UrlText,
                // eslint-disable-next-line camelcase
                second_innerpage_banner_url_enabled: innerpageBanner2UrlEnabled,
                // eslint-disable-next-line camelcase
                second_states: JSON.stringify(selectedStates2),
                // eslint-disable-next-line camelcase
                second_publish_datetime: publishDateTime2,
                // eslint-disable-next-line camelcase
                second_end_datetime: endDateTime2,
                // eslint-disable-next-line camelcase
                overlap_message: overlapMessage,
                // eslint-disable-next-line camelcase
                countdown_timer_enabled: countdownTimerEnabled,
            },
        }).then((_res) => {
            notify('Successfully updated.');
        }).catch((error) => {
            notify('Error: content and URL can\'t be blank', 'error');
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [
        homepageBannerEnabled,
        homepageBannerContent,
        homepageBannerUrl,
        homepageBannerUrlText,
        homepageBannerUrlEnabled,
        homepageBannerStartQuoteUrl,
        homepageBannerStartQuoteUrlText,
        homepageBannerStartQuoteUrlEnabled,
        innerpageBannerEnabled,
        innerpageBannerContent,
        innerpageBannerUrl,
        innerpageBannerUrlText,
        innerpageBannerUrlEnabled,
        innerpageBannerStartQuoteUrl,
        innerpageBannerStartQuoteUrlText,
        innerpageBannerStartQuoteUrlEnabled,
        selectedStates,
        publishDateTime,
        endDateTime,
        homepageBanner2Enabled,
        homepageBanner2Content,
        homepageBanner2Url,
        homepageBanner2UrlText,
        homepageBanner2UrlEnabled,
        innerpageBanner2Enabled,
        innerpageBanner2Content,
        innerpageBanner2Url,
        innerpageBanner2UrlText,
        innerpageBanner2UrlEnabled,
        selectedStates2,
        publishDateTime2,
        endDateTime2,
        overlapMessage,
        countdownTimerEnabled,
        notify,
    ]);

    return (
        <Grid container className={classes.root}>
            <Card >
                <CardContent>{
                    isLoading ? <p>Loading ...</p> : (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        component="h2"
                                        display="inline"
                                        variant="title"
                                    >
                                        Rediscover Foremost Phase 1
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={styles.my_3}>
                                    <Typography
                                        gutterBottom
                                        component="b"
                                        display="inline"
                                        variant="title"
                                    >
                                        Homepage Banner Promo Phase 1
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            checked={homepageBannerEnabled}
                                            disabled={Permissions('promo_notification_settings', 'update') !== true}
                                            onChange={handleHomepageBannerEnabledChange}
                                        />}
                                        label="Enabled"
                                    />
                                </Grid>
                                {
                                    homepageBannerEnabled && (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="homepage-banner-content"
                                                label="Content"
                                                type="text"
                                                value={homepageBannerContent}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleHomepageBannerContentChange}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    homepageBannerEnabled && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="homepage-banner-url-enabled"
                                                        color="primary"
                                                        checked={homepageBannerUrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleHomepageBannerUrlEnabledChange}
                                                    />}
                                                    label="Learn More CTA"
                                                />
                                            </Grid>
                                            {
                                                homepageBannerUrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner-url-text"
                                                                label="Learn More CTA text"
                                                                type="text"
                                                                value={homepageBannerUrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBannerUrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner-url"
                                                                label="URL"
                                                                type="text"
                                                                value={homepageBannerUrl}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBannerUrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="homepage-banner-url-enabled"
                                                        color="primary"
                                                        checked={homepageBannerStartQuoteUrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleHomepageBannerStartQuoteUrlEnabledChange}
                                                    />}
                                                    label="Start Quote CTA Enabled"
                                                />
                                            </Grid>
                                            {
                                                homepageBannerStartQuoteUrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner-start-quote-url-text"
                                                                label="Start Quote CTA text"
                                                                type="text"
                                                                value={homepageBannerStartQuoteUrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBannerStartQuoteUrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner-start-quote-url"
                                                                label="URL"
                                                                type="text"
                                                                value={homepageBannerStartQuoteUrl}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBannerStartQuoteUrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                                <Grid item xs={12} className={styles.my_3}>
                                    <Typography
                                        gutterBottom
                                        component="b"
                                        display="inline"
                                        variant="title"
                                    >
                                        Innerpage Banner Promo Phase 1
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            checked={innerpageBannerEnabled}
                                            disabled={Permissions('promo_notification_settings', 'update') !== true}
                                            onChange={handleInnerpageBannerEnabledChange}
                                        />}
                                        label="Enabled"
                                    />
                                </Grid>
                                {
                                    innerpageBannerEnabled && (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="inner-banner-content"
                                                label="Content"
                                                type="text"
                                                value={innerpageBannerContent}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleInnerpageBannerContentChange}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    innerpageBannerEnabled && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="innerpage-banner-url-enabled"
                                                        color="primary"
                                                        checked={innerpageBannerUrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleInnerpageBannerUrlEnabledChange}
                                                    />}
                                                    label="Learn More CTA"
                                                />
                                            </Grid>
                                            {
                                                innerpageBannerUrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner-url-text"
                                                                label="Learn More CTA text"
                                                                type="text"
                                                                value={innerpageBannerUrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBannerUrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner-url"
                                                                label="URL"
                                                                type="text"
                                                                value={innerpageBannerUrl}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBannerUrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="innerpage-banner-start-quote-url-enabled"
                                                        color="primary"
                                                        checked={innerpageBannerStartQuoteUrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleInnerpageBannerStartQuoteUrlEnabledChange}
                                                    />}
                                                    label="Start Quote CTA Enabled"
                                                />
                                            </Grid>
                                            {
                                                innerpageBannerStartQuoteUrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner-start-quote-url-text"
                                                                label="Start Quote CTA text"
                                                                type="text"
                                                                value={innerpageBannerStartQuoteUrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBannerStartQuoteUrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner-start-quote-url"
                                                                label="URL"
                                                                type="text"
                                                                value={innerpageBannerStartQuoteUrl}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBannerStartQuoteUrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    (homepageBannerEnabled || innerpageBannerEnabled) && states && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl variant="filled" className={classes.formControl}>
                                                    <InputLabel
                                                        shrink
                                                        required
                                                        id="states"
                                                        className={styles.input_label}
                                                    >
                                                        States
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        labelId="states"
                                                        value={selectedStates}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleStatesChange}
                                                    >
                                                        {
                                                            states.map((state) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={state.slug}
                                                                        value={state.slug}
                                                                    >
                                                                        { state.name }
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="publish-datetime"
                                                    label="Publish Date"
                                                    type="datetime-local"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={publishDateTime}
                                                    disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                    onChange={handlePublishDateTimeChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="end-datetime"
                                                    label="End Date Time"
                                                    type="datetime-local"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={endDateTime}
                                                    disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                    onChange={handleEndDateTimeChange}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                                {
                                    (homepageBannerEnabled || innerpageBannerEnabled) && (homepageBanner2Enabled || innerpageBanner2Enabled) &&
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="overlap-message"
                                                label="Phase Overlap Message"
                                                type="text"
                                                value={overlapMessage}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleOverlapMessageChange}
                                            />
                                        </Grid>
                                }
                            </Grid>

                            <hr className="delimiter" />

                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        component="h2"
                                        display="inline"
                                        variant="title"
                                    >
                                        Rediscover Foremost Phase 2
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={styles.my_3}>
                                    <Typography
                                        gutterBottom
                                        component="b"
                                        display="inline"
                                        variant="title"
                                    >
                                        Homepage Banner Promo Phase 2
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            checked={homepageBanner2Enabled}
                                            disabled={Permissions('promo_notification_settings', 'update') !== true}
                                            onChange={handleHomepageBanner2EnabledChange}
                                        />}
                                        label="Enabled"
                                    />
                                </Grid>
                                {
                                    homepageBanner2Enabled && (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="homepage-banner2-content"
                                                label="Content"
                                                type="text"
                                                value={homepageBanner2Content}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleHomepageBanner2ContentChange}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    homepageBanner2Enabled && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="homepage-banner2-url-enabled"
                                                        color="primary"
                                                        checked={homepageBanner2UrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleHomepageBanner2UrlEnabledChange}
                                                    />}
                                                    label="Learn More CTA"
                                                />
                                            </Grid>
                                            {
                                                homepageBanner2UrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner2-url-text"
                                                                label="Learn More CTA text"
                                                                type="text"
                                                                value={homepageBanner2UrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBanner2UrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="homepage-banner2-url"
                                                                label="URL"
                                                                type="text"
                                                                value={homepageBanner2Url}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleHomepageBanner2UrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                                <Grid item xs={12} className={styles.my_3}>
                                    <Typography
                                        gutterBottom
                                        component="b"
                                        display="inline"
                                        variant="title"
                                    >
                                        Innerpage Banner Promo Phase 2
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            checked={innerpageBanner2Enabled}
                                            disabled={Permissions('promo_notification_settings', 'update') !== true}
                                            onChange={handleInnerpageBanner2EnabledChange}
                                        />}
                                        label="Enabled"
                                    />
                                </Grid>
                                {
                                    innerpageBanner2Enabled && (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="inner-banner2-content"
                                                label="Content"
                                                type="text"
                                                value={innerpageBanner2Content}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleInnerpageBanner2ContentChange}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    innerpageBanner2Enabled && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        key="innerpage-banner2-url-enabled"
                                                        color="primary"
                                                        checked={innerpageBanner2UrlEnabled}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleInnerpageBanner2UrlEnabledChange}
                                                    />}
                                                    label="Learn More CTA"
                                                />
                                            </Grid>
                                            {
                                                innerpageBanner2UrlEnabled && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner2-url-text"
                                                                label="Learn More CTA text"
                                                                type="text"
                                                                value={innerpageBanner2UrlText}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBanner2UrlTextChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="innerpage-banner2-url"
                                                                label="URL"
                                                                type="text"
                                                                value={innerpageBanner2Url}
                                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                                onChange={handleInnerpageBanner2UrlChange}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    (homepageBanner2Enabled || innerpageBanner2Enabled) && states && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl variant="filled" className={classes.formControl}>
                                                    <InputLabel
                                                        shrink
                                                        required
                                                        id="states2"
                                                        className={styles.input_label}
                                                    >
                                                        States
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        labelId="states2"
                                                        value={selectedStates2}
                                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                        onChange={handleStates2Change}
                                                    >
                                                        {
                                                            states.map((state) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={state.slug}
                                                                        value={state.slug}
                                                                    >
                                                                        { state.name }
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="publish-datetime2"
                                                    label="Publish Date Time"
                                                    type="datetime-local"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={publishDateTime2}
                                                    disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                    onChange={handlePublishDateTime2Change}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="end-datetime2"
                                                    label="End Date Time"
                                                    type="datetime-local"
                                                    InputLabelProps={{ shrink: true }}
                                                    className={styles.formControl}
                                                    value={endDateTime2}
                                                    disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                    onChange={handleEndDateTime2Change}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                                {
                                    (homepageBanner2Enabled || innerpageBanner2Enabled) &&
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Switch
                                                    key="countdown-timer-enabled"
                                                    color="primary"
                                                    checked={countdownTimerEnabled}
                                                    disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                    onChange={handleCountdownTimerEnabledChange}
                                                />}
                                                label="Countdown Timer Enabled"
                                            />
                                        </Grid>
                                }
                            </Grid>
                        </>
                    )
                }
                </CardContent>
                <CardActions className={styles.toolbar}>
                    {
                        Permissions('promo_notification_settings', 'update') === true &&
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    }
                </CardActions>
            </Card>
        </Grid>
    );
};

PromoNotificationSettingsEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default React.memo(PromoNotificationSettingsEdit);

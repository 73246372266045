const source = {
    name: 'name',
    mainId: 'main_id',
    agentsCount: 'agents_count',
    mdfAllocation: 'mdf_allocation',
    notes: 'notes',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export {
    source,
};

import { cleanCommonParams } from './common';

const mapAgencyParams = (isCreate, params) => {
    return new Promise((resolve) => {
        resolve({
            agency: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapAgencyParams };

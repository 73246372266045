import React from 'react';

import {
    TextInput,
    NumberInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const AgencyForm = () => {
    return (
        <Grid container >
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    required
                    readOnly
                    label="Name"
                    source={source.name}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    required
                    readOnly
                    label="Main ID"
                    source={source.mainId}
                />
            </Grid>
            <Grid item xs={12}>
                <NumberInput
                    fullWidth
                    label="MDF Allocation"
                    source={source.mdfAllocation}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    label="Notes"
                    source={source.notes}
                />
            </Grid>
        </Grid>
    );
};

export default AgencyForm;

import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
} from 'react-admin';

import {
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';

const BrandsList = (props) => (
    <List
        {...props}
        title="Agencies"
        sort={{ field: source.createdAt, order: 'DESC' }}
        hasCreate={false}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField
                label="Name"
                source={source.name}
            />
            <TextField
                label="Main ID"
                source={source.mainId}
            />
            <TextField
                label="Agents Amount"
                source={source.agentsCount}
            />
            <DateField
                label="Created At"
                source={source.createdAt}
            />
            <EditButtonCustom resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default BrandsList;
